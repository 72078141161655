import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { History } from 'history';
import { ApplicationState, reducers } from './';

export default function configureStore(history: History, initialState?: ApplicationState) {
	
    const logger = createLogger();
    const middleware = [
        thunk,
        logger,
        routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const enhancers = [];
	
    const isDevelopment = process.env.NODE_ENV === 'development';
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

	const composedEnhancers = isDevelopment ?
        composeWithDevTools(applyMiddleware(...middleware), ...enhancers) :
        compose(applyMiddleware(...middleware), ...enhancers);

    return createStore(
        rootReducer,
        initialState,
        composedEnhancers
    );
}
